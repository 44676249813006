import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import OrderConfirmationPage from '~/components/Order/Confirmation/Page'
import PropTypes from 'prop-types'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'

const OrderConfirmation = () => {
  const translate = useTranslate()

  return (
    <Layout
      logoColor = '#000000'
      navTextColor = '#000000'
      strokeColor = '#000000'
    >
      <Metadata title={translate('confirmation.page_title')} />
      <OrderConfirmationPage />
    </Layout>
  )
}

OrderConfirmation.propTypes = {
  strokeColor: PropTypes.string
}

export default OrderConfirmation
