/** @jsx jsx */

import { Card, Heading, Text, jsx } from 'theme-ui'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'

const OrderConfirmationContact = () => {
  const translate = useTranslate()

  return (
    <Card
      sx={{
        width: '100%',
        marginBottom: '1.5rem',
        padding: ['32px 18px', '2.5rem']
      }}
    >
      <Heading as="p" variant="link" sx={{ marginBottom: '0.5rem' }}>
        {translate('confirmation.contact.title')}
      </Heading>

      <Text as="p">
        <a
          sx={{
            textDecoration: 'underline'
          }}
          href="mailto:hello@myhiki.com"
        >
          hello@myhiki.com
        </a>
      </Text>
    </Card>
  )
}

export default OrderConfirmationContact
